<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-button-toolbar
          key-nav
          aria-label="Toolbar with button groups"
          class="demo-inline-spacing"
        >
          <!-- group 2 -->
          <b-button-group>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="pokupiListu(true)"
            >
              {{ $t('SvaVozila') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="pokupiListu()"
            >
              {{ $t('SamoMoja') }}
            </b-button>
          </b-button-group>
        </b-button-toolbar>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-body :title="$t('Vozila')">
        <div>
          <b-table
            v-if="Object.entries(items).length"
            :items="items"
            :fields="fields"
            striped
            responsive
            class="mb-0"
          >
            <template #cell(detalji)="row">
              <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
              <b-form-checkbox
                v-model="row.detailsShowing"
                @change="row.toggleDetails"
              >
                {{ row.detailsShowing ? "Sakrij" : "Prikaži" }}
              </b-form-checkbox>
            </template>
            <template #cell(id)="data">
              <b-button-group size="sm">
                <router-link
                  :to="{
                    name: 'instruktori-vozila-prikazi',
                    params: { id: data.item.id }
                  }"
                >
                  <b-button
                    variant="info"
                    size="sm"
                  >
                    {{ $t("Pogledaj") }}
                  </b-button>
                </router-link>
              </b-button-group>
            </template>

            <!-- full detail on click -->
            <template #row-details="row">
              <b-card>
                <b-table-simple
                  small
                  responsive
                >
                  <b-tbody>
                    <b-tr
                      :variant="row.item.istekao_tehnicki === true ? 'danger' : row.item.istice_tehnicki === true ? 'warning' : ''"
                    >
                      <b-th
                        class="col-3"
                      >
                        {{ $t('DatumTehnickog') }}
                      </b-th>
                      <b-td>{{ row.item.datum_isteka_tehnickog }}</b-td>
                    </b-tr>
                    <b-tr
                      :variant="row.item.istekla_registracija === true ? 'danger' : row.item.istice_registracija === true ? 'warning' : ''"
                    >
                      <b-th
                        class="col-3"
                      >
                        {{ $t('DatumTehnickog') }}
                      </b-th>
                      <b-td>{{ row.item.datum_isteka_registracije }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th
                        class="col-3"
                      >
                        {{ $t('DoRedovnogServisa') }}
                      </b-th>
                      <b-td>{{ row.item.km_redovan_servis - row.item.stanje_putometar }} km</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
                <b-button
                  size="sm"
                  variant="outline-secondary"
                  @click="row.toggleDetails"
                >
                  {{ $t('SakrijDetalje') }}
                </b-button>
              </b-card>
            </template>

            <template #cell(status)="data">
              <b-badge :variant="status[1][data.value]">
                {{ status[0][data.value] }}
              </b-badge>
            </template>
          </b-table>
          <b-alert
            v-else
            variant="primary"
            show
          >
            <div
              class="alert-body"
              style="padding: 30px;"
            >
              <span>{{ $t('NemaRezultata') }}</span>
            </div>
          </b-alert>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BTable,
  BTableSimple,
  BTbody,
  BTr,
  BTh,
  BTd,
  BFormCheckbox,
  BButton,
  BButtonToolbar,
  BCardBody,
  BBadge,
  BButtonGroup,
  BCard,
  BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BTable,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BButton,
    BFormCheckbox,
    BCardBody,
    BBadge,
    BButtonGroup,
    BCard,
    BButtonToolbar,
    BAlert,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        'detalji',
        'marka',
        'tip',
        { key: 'regbroj', label: 'Registarski broj' },
        { key: 'pocregistracije', label: 'PocetakRegistracije' },
        { key: 'datum_isteka_registracije', label: this.$i18n.t('KrajRegistracije') },
        { key: 'id', label: 'Akcije' },
      ],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [
        {
          1: 'Current',
          2: 'Professional',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],
      svaVozila: false,
    }
  },
  mounted() {
    this.pokupiListu()
  },
  methods: {
    pokupiListu(svaVozila = false) {
      const url = svaVozila ? '/instruktori/vozila/sve' : '/instruktori/vozila'
      this.$http.get(url).then(response => {
        this.items = response.data
      })
    },
  },
}
</script>
